"use client";
import { useCallback, useEffect, useRef } from "react";
import { MarketplaceShipping, State, stateToPostProduct } from "../../../app/(navbar)/listings/create/reducerState";
import { AspectsResponse } from "../useSpecs";
import { useDispatch } from "react-redux";
import {
  setMercariAuthentication,
  setPlatformActive,
  setPlatformInactive,
} from "../../../redux/features/extension-marketplace-slice";
import { AppDispatch, useAppSelector } from "../../../redux/store";

const randomId = () => Math.floor(Math.random() * Math.pow(2, 32)).toString(16);

const extensions = {
  images: {
    extensions: {
      extensions: {
        persistedQuery: { sha256Hash: "9aa889ac01e549a01c66c7baabc968b0e4a7fa4cd0b6bd32b7599ce10ca09a10", version: 1 },
      },
    },
  },
  shipping: {
    extensions: {
      extensions: {
        persistedQuery: { sha256Hash: "7ce1b36300c0f27e9fc19a477668db32ad4d82b70981e741ebe80e05a1b309e6", version: 1 },
      },
    },
  },
  fees: {
    extensions: {
      extensions: {
        persistedQuery: { sha256Hash: "d4ee60063d341014e36f33462b0fb62e54aee9174c09d02a2c00da815e7d00b7", version: 1 },
      },
    },
  },
  createListing: {
    extensions: {
      extensions: {
        persistedQuery: { sha256Hash: "265dab5d0d382d3c83dda7d65e9ad111f47c27aa5d92c7d9a4bacd890d5e32c0", version: 1 },
      },
    },
  },
  deactivateListing: {
    extensions: {
      extensions: {
        persistedQuery: { version: 1, sha256Hash: "55bd4e7d2bc2936638e1451da3231e484993635d7603431d1a2978e3d59656f8" },
      },
    },
  },
};

const integratedRequest = (id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "GET:MERC_CREDENTIALS",
  },
  messageId: id,
});

const metadataRequest = (id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "GET:MERC_METADATA",
  },
  messageId: id,
});

const imageRequest = (state: State, extensionState: any, id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:MERC_IMAGE_UPLOAD",
    images: {
      image_urls: state.images.map((i) => ({
        image_url: i.url,
        selected: i.enabled,
      })),
    },
    csrfToken: extensionState.authentication.csrf,
    accessToken: extensionState.authentication.accessToken,
    extensions: extensions.images.extensions,
  },
  messageId: id,
});

const feesRequest = (state: State, extensionState: any, id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "GET:MERC_FEES",
    item: stateToPostProduct(state),
    csrfToken: extensionState.authentication.csrf,
    accessToken: extensionState.authentication.accessToken,
    extensions: extensions.fees.extensions,
  },
  messageId: id,
});

const delistRequest = (extensionState: any, id: string, listingId: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:MERC_DELIST_LISTING",
    listingId: listingId,
    csrfToken: extensionState.authentication.csrf,
    accessToken: extensionState.authentication.accessToken,
    extensions: extensions.deactivateListing.extensions,
  },
  messageId: id,
});

// postShippingRequest for mercari
const postShippingRequest = (state: State, extensionState: any, id: string) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:MERC_SHIPPING",
    item: stateToPostProduct(state),
    csrfToken: extensionState.authentication.csrf,
    accessToken: extensionState.authentication.accessToken,
    extensions: extensions.shipping.extensions,
  },
  messageId: id,
});

const postRequest = (state: State, extensionState: any, id: string, imageIds: string[], fees: number) => ({
  source: "SELLRAZE_WEB_REQ",
  payload: {
    message: "POST:MERC_CREATE_LISTING",
    metadata: {
      photo_ids: imageIds,
      fees: fees,
    },
    item: stateToPostProduct(state),
    csrfToken: extensionState.authentication.csrf,
    accessToken: extensionState.authentication.accessToken,
    extensions: extensions.createListing.extensions,
  },
  messageId: id,
});

type Response =
  | {
      type: "success";
      message: "GET:MERC_CATEGORY_INFO";
      payload: AspectsResponse;
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "GET:MERC_METADATA";
      payload: {
        data: {
          viewer: {
            marketplace_settings: {
              current_marketplace: {
                id: string;
              };
              sell_location: {
                latitude: number;
                longitude: number;
              };
            };
          };
        };
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "POST:MERC_IMAGE_UPLOAD";
      payload: {
        imagesIds: string[];
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "POST:MERC_SHIPPING";
      shippingServices: MarketplaceShipping[];
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "GET:MERC_FEES";
      payload: {
        data: {
          smartSalesFee: {
            fees: [
              {
                calculatedFee: number;
                extraMessage: string;
                faqId: string;
                message: string;
                __typename: string;
              },
            ];
            maxPrice: number;
            minPrice: number;
            version: string;
            __typeName: string;
          };
        };
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "POST:MERC_DELIST_LISTING";
      payload: {
        data: {
          updatedItemStatus: {
            status: string;
            __typename: string;
          };
        };
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "POST:MERC_CREATE_LISTING";
      payload: {
        data: {
          createListing: {
            id: string;
            seller: {
              id: number;
              numSellItems: number;
              __typename: string;
            };
            __typename: string;
          };
        };
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "success";
      message: "GET:MERC_CREDENTIALS";
      payload: {
        accessToken: string;
        csrf: string;
        email: string;
        id: number;
        username: string;
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    }
  | {
      type: "failed";
      message: "";
      payload: {
        type: string;
        message: string;
        code: string;
      };
      messageId: string;
      source: "SELLRAZE_WEB_RES";
    };

type OutboundMessage = {
  source: "SELLRAZE_WEB_REQ";
};

type ErrorResponse = {
  type: "failed";
  payload: {
    message: string;
  };
};

type Metadata = {
  longitude: number;
  latitude: number;
  marketplaceId: string;
};

const channel = new BroadcastChannel("SELLRAZE_WEB_WORKER");

export const useMercari = () => {
  const handlers = useRef<Record<string, (data: Response) => void>>({});
  const dispatch = useDispatch<AppDispatch>();
  const extensionState = useAppSelector((state) =>
    state.extensionMarketplaces.value.platforms.find((platform) => platform.marketplace === "MERCARI"),
  );
  if (!extensionState) {
    throw new Error("Mercari not found in state");
  }

  useEffect(() => {
    const handler = (evt: MessageEvent<Response | OutboundMessage>) => {
      if (evt.data.source === "SELLRAZE_WEB_REQ") {
        return;
      }
      const h = handlers.current[evt.data.messageId];
      delete handlers.current[evt.data.messageId];
      h?.(evt.data);
    };
    channel.addEventListener("message", handler);
    return () => {
      channel.removeEventListener("message", handler);
    };
  }, []);

  const timeout = useCallback((name: string, id: string, rej: (reason?: unknown) => void, duration = 10000) => {
    setTimeout(() => {
      delete handlers.current[id];
      rej(
        JSON.stringify({
          message: "No response while calling " + name,
          code: "23f0573e-e015-44ef-889f-33eb286e6bb2",
          type: "failed",
        }),
      );
    }, duration);
  }, []);

  const getIntegrated = useCallback((): Promise<boolean> => {
    const id = randomId();

    return new Promise((res, rej) => {
      handlers.current[id] = (data) => {
        if (data.type === "failed" && data.payload) {
          dispatch(setPlatformInactive("MERCARI"));
          rej(JSON.stringify(data.payload));
        } else if (data.message !== "GET:MERC_CREDENTIALS") {
          dispatch(setPlatformInactive("MERCARI"));
          rej("Wrong response type on getting mercari credentials");
        } else {
          // set the credentials through redux
          dispatch(setPlatformActive("MERCARI"));
          dispatch(setMercariAuthentication(data.payload));
          // if the accessToken is not null, then we are integrated
          res(!!data.payload.accessToken);
        }
      };
      channel?.postMessage(integratedRequest(id));
      setTimeout(() => {
        delete handlers.current[id];
        res(false);
      }, 5000);
      // timeout("get mercari integrated", id, rej, 5000);
    });
  }, [channel]);

  const getMetadata = useCallback((): Promise<Metadata> => {
    const id = randomId();

    return new Promise((res, rej) => {
      handlers.current[id] = (data) => {
        if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
        else if (data.message !== "GET:MERC_METADATA") rej("Wrong response type on get mercari metadata");
        else
          res({
            longitude: data.payload.data.viewer.marketplace_settings.sell_location.longitude,
            latitude: data.payload.data.viewer.marketplace_settings.sell_location.latitude,
            marketplaceId: data.payload.data.viewer.marketplace_settings.current_marketplace.id,
          });
      };

      channel?.postMessage(metadataRequest(id));
      timeout("get mercari metadata", id, rej);
    });
  }, [channel]);

  const getImageIds = useCallback(
    (state: State): Promise<string[]> => {
      const id = randomId();

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "POST:MERC_IMAGE_UPLOAD") rej("Wrong response type on get mercari image ids");
          else {
            res(data.payload.imagesIds);
          }
        };
        channel?.postMessage(imageRequest(state, extensionState, id));
        timeout("get mercari image ids", id, rej);
      });
    },
    [channel],
  );

  const delistListing = useCallback(
    async (listingId: string): Promise<boolean> => {
      const id = randomId();

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "POST:MERC_DELIST_LISTING") rej("Wrong response type on delist mercari listing");
          else res(true);
        };

        console.log("delistRequest", delistRequest(extensionState, id, listingId));

        channel?.postMessage(delistRequest(extensionState, id, listingId));
        timeout("delist mercari listing", id, rej);
      });
    },
    [channel],
  );

  const postShipping = useCallback(
    async (state: State): Promise<MarketplaceShipping[]> => {
      const id = randomId();

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "POST:MERC_SHIPPING") rej("Wrong response type on post mercari shipping");
          else {
            res(data.shippingServices); // Resolve the promise with JSON string of data.payload
          }
        };
        channel?.postMessage(postShippingRequest(state, extensionState, id));
        timeout("post mercari shipping", id, rej, 1000 * 20);
      });
    },
    [channel],
  );

  const getFees = useCallback(
    async (state: State): Promise<any> => {
      const id = randomId();

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "GET:MERC_FEES") rej("Wrong response type on get mercari fees");
          else {
            if (data.payload.data.smartSalesFee.fees.length > 0) {
              let fees = 0;
              for (let i = 0; i < data.payload.data.smartSalesFee.fees.length; i++) {
                fees += data.payload.data.smartSalesFee.fees[i].calculatedFee;
              }

              res(fees);
            } else {
              rej("No fees found for this item. Please make sure you have selected a shipping method.");
            }
          }
        };
        channel?.postMessage(feesRequest(state, extensionState, id));
        timeout("get mercari fees", id, rej, 1000 * 20);
      });
    },
    [channel],
  );

  const postListing = useCallback(
    async (state: State): Promise<any> => {
      const id = randomId();
      const [imageIds, fees] = await Promise.all([getImageIds(state), getFees(state)]);

      return new Promise((res, rej) => {
        handlers.current[id] = (data) => {
          if (data.type === "failed" && data.payload) rej(JSON.stringify(data.payload));
          else if (data.message !== "POST:MERC_CREATE_LISTING") rej("Wrong response type on post mercari listing");
          else {
            res(data.payload);
          }
        };

        channel?.postMessage(postRequest(state, extensionState, id, imageIds, fees));
        timeout("post mercari listing", id, rej, 1000 * 20);
      });
    },
    [getMetadata, getImageIds, channel],
  );

  return {
    getIntegrated,
    delistListing,
    postShipping,
    postListing,
  };
};
