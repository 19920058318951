import { useFacebook } from "./api/extension/useFacebook";
import { useMercari } from "./api/extension/useMercari";

export const marketplaceInfo = {
  AMAZON: {
    displayName: "Amazon",
    defaultId: "ATVPDKIKX0DER",
    leafCategoryOnly: false,
    hasStaticSpecs: true,
    hasDynamicSpecs: false,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
  },
  EBAY: {
    displayName: "eBay",
    defaultId: "EBAY_US",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
  },
  FACEBOOK: {
    displayName: "Facebook",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
  },
  MERCARI: {
    displayName: "Mercari",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
  },
} as const;
export type Marketplace = keyof typeof marketplaceInfo;
export const marketplaces = Object.keys(marketplaceInfo).sort() as readonly Marketplace[];

export const extensionMarketplaceInfo = {
  FACEBOOK: {
    displayName: "Facebook",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
    hook: useFacebook,
    oauthURL: "https://www.facebook.com/login/device-based/regular/login/?login_attempt=1",
  },
  MERCARI: {
    displayName: "Mercari",
    defaultId: "",
    leafCategoryOnly: true,
    hasStaticSpecs: true,
    hasDynamicSpecs: true,
    calculateFees: (listingPrice: number) => 0.12 * listingPrice,
    hook: useMercari,
    oauthURL: "https://www.mercari.com/login/",
  },
} as const;

export type ExtensionMarketplace = keyof typeof extensionMarketplaceInfo;

export const extensionMarketplaces = Object.keys(extensionMarketplaceInfo).sort() as ExtensionMarketplace[];
