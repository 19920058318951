import { configureStore } from "@reduxjs/toolkit";
import inventoryReducer from "./features/inventory-slice";
import extensionMarketplacesReducer from "./features/extension-marketplace-slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { enableMapSet } from "immer";
import registerReducer from "./features/seller_slice";
import routerReducer from "./features/router_slice";
enableMapSet();

const inventoryPersistConfig = {
  key: "inventory",
  storage,
};

const extensionMarketplacesPersistConfig = {
  key: "extensionMarketplaces",
  storage,
};

const persistedInventoryReducer = persistReducer(inventoryPersistConfig, inventoryReducer);
const persistedExtensionMarketplaceReducer = persistReducer(
  extensionMarketplacesPersistConfig,
  extensionMarketplacesReducer,
);

export const store = configureStore({
  reducer: {
    inventory: persistedInventoryReducer,
    // inventory: inventoryReducer,
    router: routerReducer,
    register: registerReducer,
    extensionMarketplaces: persistedExtensionMarketplaceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
