// redux/slices/routerSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RouterState {
  previousPath: string | null;
  currentPath: string;
}

const initialState: RouterState = {
  previousPath: null,
  currentPath: "",
};

const routerSlice = createSlice({
  name: "router",
  initialState,
  reducers: {
    setPaths: (state, action: PayloadAction<{ previous: string | null; current: string }>) => {
      state.previousPath = action.payload.previous;
      state.currentPath = action.payload.current;
    },
  },
});

export const { setPaths } = routerSlice.actions;

export default routerSlice.reducer;
