import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Marketplace } from "../../lib/platforms";

export type extensionMarketplaceState = {
  value: {
    platforms: [
      {
        marketplace: "FACEBOOK";
        active: boolean;
        authentication: {
          accessToken: string;
          id: string;
        };
      },
      {
        marketplace: "MERCARI";
        active: boolean;
        authentication: {
          accessToken: string;
          csrf: string;
          email: string;
          id: number;
          username: string;
        };
      },
    ];
  };
};

// create the initial state
const initialState = {
  value: {
    platforms: [
      {
        marketplace: "FACEBOOK",
        active: false,
        authentication: {
          accessToken: "",
          id: "",
        },
      },
      {
        marketplace: "MERCARI",
        active: false,
        authentication: {
          accessToken: "",
          csrf: "",
          email: "",
          id: 0,
          username: "",
        },
      },
    ],
  },
} as extensionMarketplaceState;

export const extensionMarketplaces = createSlice({
  name: "marketplaceExtension",
  initialState,
  reducers: {
    setPlatformActive: (state, action: PayloadAction<Marketplace>) => {
      const platform = state.value.platforms.find((platform) => platform.marketplace === action.payload);
      if (platform) {
        platform.active = true;
      }

      return state;
    },
    setPlatformInactive: (state, action: PayloadAction<Marketplace>) => {
      const platform = state.value.platforms.find((platform) => platform.marketplace === action.payload);
      if (platform) {
        platform.active = false;
      }

      return state;
    },
    setFacebookAuthentication: (state, action: PayloadAction<any>) => {
      const platform = state.value.platforms.find((platform) => platform.marketplace === "FACEBOOK");
      if (platform && platform?.marketplace == "FACEBOOK") {
        platform.authentication = action.payload;
      }
      return state;
    },
    setMercariAuthentication: (state, action: PayloadAction<any>) => {
      const platform = state.value.platforms.find((platform) => platform.marketplace === "MERCARI");
      if (platform && platform?.marketplace == "MERCARI") {
        platform.authentication = action.payload;
      }
      return state;
    },
  },
});

export const { setPlatformActive, setPlatformInactive, setFacebookAuthentication, setMercariAuthentication } =
  extensionMarketplaces.actions;
export default extensionMarketplaces.reducer;
