import {
  ApiCondition,
  ApiLengthUnit,
  ApiPIDType,
  ApiWeightUnit,
  GetInventoryProduct,
  PostInventoryProduct,
} from "../../../../lib/api/Product";
import { Marketplace, marketplaceInfo } from "../../../../lib/platforms";

export type VariantType = "color" | "size" | "style";
export type DimensionType = "length" | "width" | "height" | "weight";
export type GeneralProperty = Exclude<keyof State["general"], "categories" | "hasVariations">;
export type Mode = "create" | "draft" | "edit";
export type Category = {
  id: string;
  name: string;
  hasChildren: boolean;
};

export type Condition = ApiCondition;
export type PIDType = ApiPIDType;
export type LengthUnit = "inch" | "foot" | "centimeter" | "meter" | "millimeter" | "yard";
export type WeightUnit = "pound" | "ounce" | "gram" | "kilogram";

export const conditionToDisplay = (c: Condition | "") =>
  ({
    new: "New",
    "new-open-box": "New (Open Box)",
    "new-with-defects": "New (With Defects)",
    "certified-refurbished": "Certified Refurbished",
    "seller-refurbished": "Seller Refurbished",
    used: "Used",
    "used-like-new": "Used (Like New)",
    "used-very-good": "Used (Very Good)",
    "used-good": "Used (Good)",
    "used-acceptable": "Used (Acceptable)",
    "not-working": "Not Working",
    "": "Invalid Condition",
  })[c];

export const lengthUnitMap: Record<LengthUnit | "", ApiLengthUnit | ""> = {
  centimeter: "CENTIMETERS",
  foot: "FEET",
  inch: "INCHES",
  meter: "METERS",
  millimeter: "MILLIMETERS",
  yard: "YARDS",
  "": "",
};
export const apiLengthUnitMap: Record<ApiLengthUnit | "", LengthUnit | ""> = {
  CENTIMETERS: "centimeter",
  FEET: "foot",
  INCHES: "inch",
  METERS: "meter",
  MILLIMETERS: "millimeter",
  YARDS: "yard",
  "": "",
};
export const weightUnitMap: Record<WeightUnit | "", ApiWeightUnit | ""> = {
  gram: "GRAMS",
  kilogram: "KILOGRAMS",
  ounce: "OUNCES",
  pound: "POUNDS",
  "": "",
};
export const apiWeightUnitMap: Record<ApiWeightUnit | "", WeightUnit | ""> = {
  GRAMS: "gram",
  KILOGRAMS: "kilogram",
  OUNCES: "ounce",
  POUNDS: "pound",
  "": "",
};

export type MarketplaceShipping = {
  marketplace: Marketplace;
  carrierName: string;
  displayName: string;
  id: string;
  shippingTime: string;
  rateMinimum: number;
  rateMaximum: number;
  pricingUnit: string;
  zipCode: string;
};

export type State = {
  version: number;
  listingId: string;
  inventoryId: string;
  platforms: {
    marketplace: Marketplace;
    marketplaceIds: string[];
  }[];
  general: {
    itemName: string;
    brandName: string;
    externalId: string;
    imported: boolean;
    externalIdType: PIDType | "";
    sku: string;
    placeholderSku: string;
    modelNumber: string;
    categories: {
      marketplace: Marketplace;
      categoryPath: Category[];
      categoryName: string;
    }[];
    hasVariations: boolean;
    condition: Condition | "";
    description: string;
    notes: string;
    itemPurchaseDate: string;
    quantity: string;
  };
  images: {
    enabled: boolean;
    url: string;
  }[];
  specs: {
    marketplace: Marketplace;
    specs: {
      [key: string]: {
        formValue: string;
        value: any;
        required?: boolean;
        dynamic?: boolean;
      };
    };
    dirtySpecs: Set<string>;
  }[];
  variants: {
    type: VariantType;
    values: string[];
  }[];
  itemDimensions: {
    length: {
      value: string;
      unit: LengthUnit | "";
    };
    width: {
      value: string;
      unit: LengthUnit | "";
    };
    height: {
      value: string;
      unit: LengthUnit | "";
    };
    weight: {
      value: string;
      unit: WeightUnit | "";
    };
  };
  packageDimensions: {
    length: {
      value: string;
      unit: LengthUnit | "";
    };
    width: {
      value: string;
      unit: LengthUnit | "";
    };
    height: {
      value: string;
      unit: LengthUnit | "";
    };
    weight: {
      value: string;
      unit: WeightUnit | "";
    };
  };
  pricing: {
    platforms: {
      marketplace: Marketplace;
      listingPrice: string;
      platformFees: string;
      defaultPlatformFees: string;
      shipping: {
        carrierName: string;
        displayName: string;
        id: string;
        shippingTime: string;
        rateMinimum: number;
        rateMaximum: number;
        pricingUnit: string;
        zipCode: string;
      }[];
    }[];
    quantity: string;
    currency: string;
    amazonFBA: boolean;
    itemCost: string;
    defaultListingPrice: string;
  };
  mode: Mode;
};

export const updateState = (state: State): State => {
  const s = structuredClone(state);
  switch (s.version ?? 0) {
    case 0:
      // Version 1 added inventoryId and listingId
      s.inventoryId ??= "";
      s.listingId ??= "";
    case 1:
      // Version 2 added the dirtySpecs tracking to only send edited specs
      s.specs = s.specs.map((spec) => ({
        marketplace: spec.marketplace,
        specs: spec.specs,
        dirtySpecs: new Set(Object.keys(spec.specs)),
      }));
    case 2:
      // Version 3 removed LATER as a platform options

      // @ts-ignore the type of platforms was changed in version 4
      s.platforms = s.platforms.filter((p: string) => p !== "LATER");
    case 3:
      // Version 4 changed unit constants and platforms type
      s.itemDimensions.length.unit =
        apiLengthUnitMap[s.itemDimensions.length.unit as ApiLengthUnit] ?? s.itemDimensions.length.unit;
      s.itemDimensions.width.unit =
        apiLengthUnitMap[s.itemDimensions.width.unit as ApiLengthUnit] ?? s.itemDimensions.width.unit;
      s.itemDimensions.height.unit =
        apiLengthUnitMap[s.itemDimensions.height.unit as ApiLengthUnit] ?? s.itemDimensions.height.unit;
      s.itemDimensions.weight.unit =
        apiWeightUnitMap[s.itemDimensions.weight.unit as ApiWeightUnit] ?? s.itemDimensions.weight.unit;
      s.packageDimensions.length.unit =
        apiLengthUnitMap[s.packageDimensions.length.unit as ApiLengthUnit] ?? s.packageDimensions.length.unit;
      s.packageDimensions.width.unit =
        apiLengthUnitMap[s.packageDimensions.width.unit as ApiLengthUnit] ?? s.packageDimensions.width.unit;
      s.packageDimensions.height.unit =
        apiLengthUnitMap[s.packageDimensions.height.unit as ApiLengthUnit] ?? s.packageDimensions.height.unit;
      s.packageDimensions.weight.unit =
        apiWeightUnitMap[s.packageDimensions.weight.unit as ApiWeightUnit] ?? s.packageDimensions.weight.unit;
      s.platforms = (s.platforms as any as Marketplace[]).map((p) => ({
        marketplace: p,
        marketplaceIds: [marketplaceInfo[p].defaultId],
      }));
    case 4:
      // Verion 5 added the description field and moved condition
      s.general.description = "";

      // @ts-ignore
      s.general.condition = s.pricing.condition;

      // @ts-ignore
      delete s.pricing.condition;
    default:
      return s;
  }
};

function unnestSpecs(specs: State["specs"][0]["specs"], formValue: string) {
  const o = structuredClone(specs) as any;
  if (typeof o === "object") {
    for (const k of Object.keys(o)) {
      o[k] = unnestSpecs(o[k].value, o[k].formValue);
    }
  }
  if (typeof o === "number" && formValue === "") {
    return -1;
  }
  return o;
}

const filterUnsetSpecs = (specs: State["specs"][0]["specs"], dirtySpecs: Set<string>) => {
  const out = {} as State["specs"][0]["specs"];
  for (const key in specs) {
    if (dirtySpecs.has(key)) out[key] = specs[key];
  }

  return out;
};

export const stateToPostProduct = (state: State): PostInventoryProduct => {
  let quantity = "";
  if (state.general.quantity != "") {
    quantity = state.general.quantity;
  } else {
    quantity = state.pricing.quantity;
  }
  return {
    inventory_id: state.inventoryId,
    listing_id: state.listingId,
    restricted_allocation: 0,
    created_at: "1970-01-01T00:00:00.000Z",
    updated_at: "1970-01-01T00:00:00.000Z",

    product_name: state.general.itemName,
    quantity: Number(quantity),
    platforms: {
      marketplaces: state.platforms.map((m) => ({
        marketplace: m.marketplace,
        marketplace_ids: m.marketplaceIds,
      })),
    },
    general_information: {
      has_variation: state.general.hasVariations,
      has_brand_name: state.general.brandName !== "",
      has_pid: state.general.externalId !== "",
      brand_name: state.general.brandName,
      imported: state.general.imported,
      sku: state.general.sku || state.general.placeholderSku,
      external_pid: state.general.externalId,
      external_pid_type: state.general.externalIdType,
      model_number: state.general.modelNumber,
      description: state.general.description,
      notes: state.general.notes,
      item_purchase_date: state.general.itemPurchaseDate,
    },
    categories: {
      category_type_array: state.general.categories.map((c) => ({
        category_id: c.categoryPath.map((c) => c.id),
        category_name: c.categoryName,
        marketplace: c.marketplace,
      })),
    },
    images: {
      image_urls: state.images.map((i) => ({
        image_url: i.url,
        selected: i.enabled,
      })),
    },
    product_specifications: {
      marketplace_specs: state.specs.map((s) => ({
        marketplace: s.marketplace,
        marketplace_id: marketplaceInfo[s.marketplace].defaultId,
        specifications: {
          parent: "",
          values: Array.from(s.dirtySpecs),
          aspects: unnestSpecs(filterUnsetSpecs(s.specs, s.dirtySpecs), ""),
        },
      })),
    },
    variations: {
      variation_type: "",
      size: {
        type: "size",
        values: state.variants.find((v) => v.type === "size")?.values ?? null,
      },
      color: {
        type: "color",
        values: state.variants.find((v) => v.type === "color")?.values ?? null,
      },
      style: {
        type: "style",
        values: state.variants.find((v) => v.type === "style")?.values ?? null,
      },
    },
    shipping: {
      item_dimensions: {
        length: Number(state.itemDimensions.length.value),
        width: Number(state.itemDimensions.width.value),
        height: Number(state.itemDimensions.height.value),
        unit: lengthUnitMap[state.itemDimensions.length.unit],
      },
      package_dimensions: {
        length: Number(state.packageDimensions.length.value),
        width: Number(state.packageDimensions.width.value),
        height: Number(state.packageDimensions.height.value),
        unit: lengthUnitMap[state.packageDimensions.length.unit],
      },
      weight: {
        item_weight: Number(state.itemDimensions.weight.value),
        package_weight: Number(state.packageDimensions.weight.value),
        weight_unit: weightUnitMap[state.packageDimensions.weight.unit],
      },
      warehouse_location: null,
    },
    pricing: {
      condition: state.general.condition,
      item_cost: Number(state.pricing.itemCost),
      currency: state.pricing.currency,
      marketplace_pricing: state.pricing.platforms.map((p) => ({
        marketplace: p.marketplace,
        shipping_services: p.shipping.map((s) => ({
          carrier_name: s.carrierName,
          display_name: s.displayName,
          id: s.id,
          rate_maximum: s.rateMaximum,
          rate_minimum: s.rateMinimum,
          shipping_time: s.shippingTime,
          pricing_unit: s.pricingUnit,
          zip_code: s.zipCode,
        })),
        currency: state.pricing.currency,
        presale_price: 0,
        listing_price: p.listingPrice === "" ? null : Number(p.listingPrice),
        platform_fees: p.platformFees === "" ? null : Number(p.platformFees),
        default_platform_fees: p.defaultPlatformFees === "" ? null : Number(p.defaultPlatformFees),
      })),
      default_listing_price: Number(state.pricing.defaultListingPrice),
      amazon_fba: state.pricing.amazonFBA,
    },
  };
};

const lengthUnitMapReverse: Record<ApiLengthUnit | "", LengthUnit | ""> = {
  CENTIMETERS: "centimeter",
  FEET: "foot",
  INCHES: "inch",
  METERS: "meter",
  MILLIMETERS: "millimeter",
  YARDS: "yard",
  "": "",
};

const weightUnitMapReverse: Record<ApiWeightUnit | "", WeightUnit | ""> = {
  POUNDS: "pound",
  OUNCES: "ounce",
  GRAMS: "gram",
  KILOGRAMS: "kilogram",
  "": "",
};

// Taken from https://bugzilla.mozilla.org/attachment.cgi?id=351506&action=diff
const rfc3339Regex = new RegExp(
  "^([0-9]{4})-([0-9]{2})-([0-9]{2})" +
    "([Tt]([0-9]{2}):([0-9]{2}):([0-9]{2})(\\.[0-9]+)?)?" +
    "(([Zz]|([+-])([0-9]{2}):([0-9]{2})))?$",
);

const formatDatetime = (datetime: string) => {
  const d = new Date(datetime);
  const year = d.getFullYear().toString();
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");
  const hour = d.getHours().toString().padStart(2, "0");
  const minute = d.getMinutes().toString().padStart(2, "0");
  const second = d.getSeconds().toString().padStart(2, "0");
  return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
};

function nestSpecs(specs: any): State["specs"][0]["specs"] {
  const o = structuredClone(specs);
  if (typeof o !== "object") return o;
  for (const key in o) {
    let formValue: string;
    if (typeof o[key] === "object") {
      formValue = "";
    } else if (typeof o[key] === "string") {
      if (rfc3339Regex.test(o[key])) {
        formValue = formatDatetime(o[key]);
      } else {
        formValue = o[key];
      }
    } else if (typeof o[key] === "number") {
      formValue = o[key] === -1 ? "" : o[key].toString();
    } else {
      formValue = o[key]?.toString?.() ?? "";
    }
    o[key] = {
      formValue,
      value: nestSpecs(o[key]),
      required: true,
    };
  }
  return o;
}

export const getProductToState = (prod: GetInventoryProduct, mode: Mode): State => ({
  version: -1,
  inventoryId: prod.inventory_id,
  listingId: prod.listing_id,
  platforms:
    prod.platforms.marketplaces?.map((m) => ({
      marketplace: m.marketplace,
      marketplaceIds: m.marketplace_ids ?? [],
    })) ?? [],
  general: {
    itemName: prod.product_name,
    brandName: prod.general_information.brand_name,
    imported: prod.general_information.imported,
    externalId: prod.general_information.external_pid,
    externalIdType: prod.general_information.external_pid_type,
    sku: prod.general_information.sku,
    placeholderSku: prod.general_information.sku === "" ? (crypto.randomUUID?.() ?? fallbackUUID()) : "",
    modelNumber: prod.general_information.model_number,
    categories:
      prod.categories.category_type_array?.map((c) => ({
        marketplace: c.marketplace,
        categoryName: c.category_name,
        categoryPath:
          c.category_id?.map((i) => ({
            id: i,
            name: "",
            hasChildren: false,
          })) ?? [],
      })) ?? [],
    hasVariations: prod.general_information.has_variation,
    condition: prod.pricing.condition,
    description: prod.general_information.description ?? "",
    notes: prod.general_information.notes ?? "",
    itemPurchaseDate: prod.general_information.item_purchase_date ?? "",
    quantity: "",
  },
  images:
    prod.images.image_urls?.map((i) => ({
      url: i.image_url,
      enabled: i.selected,
    })) ?? [],
  specs:
    prod.product_specifications.marketplace_specs?.map((m) => ({
      marketplace: m.marketplace,
      specs: nestSpecs(m.specifications.aspects),
      dirtySpecs: new Set(Object.keys(m.specifications.aspects)),
    })) ?? [],
  variants: [
    {
      type: "color",
      values: prod.variations.color.values,
    },
    {
      type: "size",
      values: prod.variations.size.values,
    },
    {
      type: "style",
      values: prod.variations.style.values,
    },
  ].filter((v) => v.values !== null) as State["variants"],
  itemDimensions: {
    length: {
      value: prod.shipping.item_dimensions.length === 0 ? "" : prod.shipping.item_dimensions.length.toFixed(2),
      unit: apiLengthUnitMap[prod.shipping.item_dimensions.unit],
    },
    width: {
      value: prod.shipping.item_dimensions.width === 0 ? "" : prod.shipping.item_dimensions.width.toFixed(2),
      unit: apiLengthUnitMap[prod.shipping.item_dimensions.unit],
    },
    height: {
      value: prod.shipping.item_dimensions.height === 0 ? "" : prod.shipping.item_dimensions.height.toFixed(2),
      unit: apiLengthUnitMap[prod.shipping.item_dimensions.unit],
    },
    weight: {
      value: prod.shipping.weight.item_weight === 0 ? "" : prod.shipping.weight.item_weight.toFixed(2),
      unit: apiWeightUnitMap[prod.shipping.weight.weight_unit],
    },
  },
  packageDimensions: {
    length: {
      value: prod.shipping.package_dimensions.length === 0 ? "" : prod.shipping.package_dimensions.length.toFixed(2),
      unit: apiLengthUnitMap[prod.shipping.package_dimensions.unit],
    },
    width: {
      value: prod.shipping.package_dimensions.width === 0 ? "" : prod.shipping.package_dimensions.width.toFixed(2),
      unit: apiLengthUnitMap[prod.shipping.package_dimensions.unit],
    },
    height: {
      value: prod.shipping.package_dimensions.height === 0 ? "" : prod.shipping.package_dimensions.height.toFixed(2),
      unit: apiLengthUnitMap[prod.shipping.package_dimensions.unit],
    },
    weight: {
      value: prod.shipping.weight.package_weight === 0 ? "" : prod.shipping.weight.package_weight.toFixed(2),
      unit: apiWeightUnitMap[prod.shipping.weight.weight_unit],
    },
  },
  pricing: {
    platforms:
      prod.pricing.marketplace_pricing?.map((m) => ({
        marketplace: m.marketplace,
        listingPrice: m.listing_price?.toFixed(2) ?? "",
        platformFees: m.platform_fees?.toFixed(2) ?? "",
        defaultPlatformFees: m.default_platform_fees?.toFixed(2) ?? "",
        shipping:
          m.shipping_services?.map((s) => ({
            carrierName: s.carrier_name,
            displayName: s.display_name,
            id: s.id,
            rateMaximum: s.rate_maximum,
            rateMinimum: s.rate_minimum,
            shippingTime: s.shipping_time,
            pricingUnit: s.pricing_unit,
            zipCode: s.zip_code,
          })) ?? [],
      })) ?? [],
    quantity: prod.quantity.toFixed(0),
    currency: prod.pricing.currency,
    amazonFBA: prod.pricing.amazon_fba,
    itemCost: prod.pricing.item_cost.toFixed(2),
    defaultListingPrice: prod.pricing.default_listing_price?.toFixed(2) ?? "",
  },
  mode: mode,
});

// This function ill only be called in dev. It is a fallback for when
// randomUUID is not available, which only happens when using https
// instead of https.
const fallbackUUID = () => {
  console.warn("If you are seeing this warning, contact us and let us know there's an issue with UUID generation.");
  const twoBytes = () => Math.floor(Math.random() * Math.pow(2, 16)).toString(16);

  return `${twoBytes()}${twoBytes()}-${twoBytes()}-${twoBytes()}-${twoBytes()}-${twoBytes()}${twoBytes()}${twoBytes()}`;
};

export const createInitialState = (mode: Mode = "draft"): State => ({
  version: 5,
  listingId: "",
  inventoryId: "",
  platforms: [],
  general: {
    hasVariations: false,
    categories: [],
    itemName: "",
    imported: false,
    brandName: "",
    externalId: "",
    externalIdType: "",
    modelNumber: "",
    sku: "",
    placeholderSku: crypto.randomUUID?.() ?? fallbackUUID(),
    condition: "",
    description: "",
    notes: "",
    itemPurchaseDate: "",
    quantity: "",
  },
  images: [],
  variants: [],
  specs: [],
  itemDimensions: {
    length: {
      value: "",
      unit: "",
    },
    width: {
      value: "",
      unit: "",
    },
    height: {
      value: "",
      unit: "",
    },
    weight: {
      value: "",
      unit: "",
    },
  },
  packageDimensions: {
    length: {
      value: "",
      unit: "",
    },
    width: {
      value: "",
      unit: "",
    },
    height: {
      value: "",
      unit: "",
    },
    weight: {
      value: "",
      unit: "",
    },
  },
  pricing: {
    platforms: [],
    quantity: "",
    itemCost: "",
    defaultListingPrice: "",
    currency: "USD",
    amazonFBA: false,
  },
  mode: mode,
});
