// src/redux/slices/registerSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Address = {
  city: string;
  company?: string;
  country: string;
  email?: string;
  is_residential?: boolean;
  name?: string;
  phone?: string;
  state: string;
  street1: string;
  street2?: string;
  street3?: string;
  street_no?: string;
  zip: string;
};

interface RegisterState {
  name: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  loadRegister: boolean;
  error: string;
  address: Address;
}

const initialState: RegisterState = {
  name: "",
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  loadRegister: false,
  error: "",
  address: {
    city: "",
    country: "",
    state: "",
    street1: "",
    zip: "",
  },
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setFirstName(state, action: PayloadAction<string>) {
      state.firstName = action.payload;
    },
    setLastName(state, action: PayloadAction<string>) {
      state.lastName = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    setPasswordConfirm(state, action: PayloadAction<string>) {
      state.passwordConfirm = action.payload;
    },
    setLoadRegister(state, action: PayloadAction<boolean>) {
      state.loadRegister = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    setAddress(state, action: PayloadAction<Address>) {
      state.address = action.payload;
    },
    resetRegisterState(state) {
      state = initialState;
    },
  },
});

export const {
  setName,
  setFirstName,
  setLastName,
  setUserName,
  setEmail,
  setPassword,
  setPasswordConfirm,
  setLoadRegister,
  setError,
  resetRegisterState,
  setAddress,
} = registerSlice.actions;

export default registerSlice.reducer;
